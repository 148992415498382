<template>
  <div class="ma-4">
    <portal-form
      v-if="formLoaded"
      :info-card="revision.infoCard"
      :info-card-text="revision.infoCardText"
      :sections="revision.sections"
      :section-type="revision.sectionType"
      :init-form-data="initFormData"
      title=""
      @submit="saveSubmission"
    ></portal-form>
  </div>
</template>
<script>
// Olimova, Yosuman
import { ref, computed, onMounted } from '@vue/composition-api'
import { getFormData } from '@/components/forms/functions.js'
const formId = '668be62f7725f42e98222f9c'

export default {
  components: {
    PortalForm: () => import('@/components/forms/Form')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const aidy = computed({
      get: () => root.$store.state['work-study'].aidy,
      set: (aidy) => root.$store.commit('work-study/setAidy', aidy)
    })
    const formLoaded = ref(false)
    const id = ref('')
    const placements = ref([])
    const revision = ref({})
    const initFormData = ref({})
    const inChecklist = ref(false)

    onMounted(async () => {
      const rec = await root.$feathers.service('forms/base').get(formId)
      const data = await root.$feathers.service('forms/revision').get(rec.revisions.active)
      data.sections[1].inputs[1].inputs[0].readonly = true
      data.sections[1].inputs[1].inputs[1].readonly = true
      revision.value = data
      await loadData()
    })

    async function loadData () {
      const { data } = await root.$feathers.service('work-study/checklist').find({ query: { aidy: aidy.value, pidm: user.value.pidm } })
      if (data.length === 0) {
        root.$router.replace('/scotswork')
        return
      }
      const { _id, positions, checklist } = data[0]
      id.value = _id
      placements.value = positions
      const rec = checklist.find(({ code }) => code === 'SC')
      inChecklist.value = rec != null
      if (rec) {}
      initFormData.value['ScotsWork Placement(s)'] = positions.map(({ name, supervisor, hours }) => { return { 'Placement': name, 'Supervisor': supervisor, 'Intended Weekly Hours': hours || '' } })
      formLoaded.value = true
    }

    async function saveSubmission (data) {
      // Convert the submitted data object to an array to be used in the form submission
      const dataArr = getFormData(revision.value.sections, data)
      // Save to the form submission collection
      const { _id: submissionId } = await root.$feathers.service('forms/submission').create({
        user: user.value._id,
        name: user.value.name,
        form: {
          name: 'ScotsWork Contract',
          base: formId,
          revision: revision.value._id
        },
        status: 'Submitted',
        submittedDate: new Date(),
        dataArr
      })
      // Update the work-study/students record to show the hours chosen and the date the contract was last submitted
      const patch = {}
      const arrayFilters = []
      let i = 1
      data['ScotsWork Placement(s)'].forEach(({ 'Placement': name, 'Intended Weekly Hours': hours }) => {
        patch['positions.$[posn' + i + '].hours'] = hours
        arrayFilters.push({ ['posn' + i + '.name']: name })
        i++
      })
      if (inChecklist.value) {
        patch['checklist.$[chk].completed'] = new Date()
        patch['checklist.$[chk].submission'] = submissionId
        // update the positions array to include the hours
        arrayFilters.push({ 'chk.code': 'SC' })
      } else {
        patch.$push = { checklist: { code: 'SC', completed: new Date(), submission: submissionId } }
      }
      if (arrayFilters.length > 0) {
        await root.$feathers.service('work-study/checklist').patch(id.value, patch, { query: { arrayFilters } })
      } else {
        await root.$feathers.service('work-study/checklist').patch(id.value, patch)
      }
      root.$store.dispatch('main/snackbar', { color: 'success', text: 'ScotsWork Contract Saved Successfully' })
      root.$router.push('/scotswork')
    }

    return {
      user,
      formLoaded,
      id,
      placements,
      revision,
      initFormData,
      saveSubmission
    }
  }
}
</script>
