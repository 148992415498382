exports.getFormData = function (sections, formData) {
  const tempData = JSON.parse(JSON.stringify(formData))
  for (let i = 0; i < sections.length; i++) {
    for (let j = 0; j < sections[i].inputs.length; j++) {
      let { input, label } = sections[i].inputs[j]
      switch (input) {
        case 'html-block':
          // Skip HTML blocks because they will not have any inputs
          delete tempData[label]
          continue
        // Find any combobox entries where the selected data is an object with text/value and change the saved data to the value
        case 'combobox':
          if (label && label in tempData) {
            if (typeof (tempData[label]) === 'object') {
              if ('value' in tempData[label]) tempData[label] = tempData[label].value
              else if ('text' in tempData[label]) tempData[label] = tempData[label].text
            }
          }
          break
        case 'repeatable-block':
          // The value of this one is an array of objects; convert each object into an array so it is an array of arrays and not an array of objects
          if (label && label in tempData && Array.isArray(tempData[label]) && tempData[label].length > 0) {
            for (let i = 0; i < tempData[label].length; i++) {
              const arr = []
              for (const field in tempData[label][i]) {
                arr.push({ field, value: tempData[label][i][field] })
              }
              tempData[label].splice(i, 1, arr)
            }
          }
          break
      }
    }
  }
  // Convert the hashed data object into an array of field/value pairs (this will fix any problems with periods in the field names)
  const arr = []
  for (let field in tempData) {
    arr.push({ field, value: tempData[field] })
  }
  return arr
}
